.heading_small_m {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: -0.032em;
}
.heading_small_b {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: -0.032em;
}
.heading_medium_m {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.6875rem;
  letter-spacing: -0.036em;
}
.heading_medium_b {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.6875rem;
  letter-spacing: -0.036em;
}
.heading_large_m {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.875rem;
  letter-spacing: -0.04em;
}
.heading_large_b {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.875rem;
  letter-spacing: -0.04em;
}
.heading_xlarge_m {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.25rem;
  letter-spacing: -0.096em;
}
.heading_xlarge_b {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.25rem;
  letter-spacing: -0.096em;
}
.heading_2xlarge_r {
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 2.625rem;
  letter-spacing: -0.056em;
}
.heading_2xlarge_m {
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 2.625rem;
  letter-spacing: -0.056em;
}
.heading_2xlarge_b {
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 2.625rem;
  letter-spacing: -0.056em;
}
.heading_3xlarge_m {
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3rem;
  letter-spacing: -0.192em;
}
.heading_3xlarge_b {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 3rem;
  letter-spacing: -0.192em;
}

.body_2xsmall_r,
.dop_organization
  .section_group
  .section
  .box
  .user_group
  ul
  .dop_drag_list
  .member
  .additional {
  font-size: 0.6875rem;
  font-weight: 400;
  line-height: 1.03125rem;
  letter-spacing: -0.044em;
}
.body_2xsmall_m {
  font-size: 0.6875rem;
  font-weight: 500;
  line-height: 1.03125rem;
  letter-spacing: -0.044em;
}
.body_xsmall_m {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.125rem;
  letter-spacing: -0.048em;
}
.body_xsmall_r {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: -0.048em;
}
.body_small_m,
.dop_organization .wrap_popup .wrap_popup_header p.count_txt {
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1.21875rem;
  letter-spacing: -0.052em;
}
.body_small_r {
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.21875rem;
  letter-spacing: -0.052em;
}
.body_medium_b {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.3125rem;
  letter-spacing: -0.028em;
}
.body_medium_m {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.3125rem;
  letter-spacing: -0.028em;
}
.body_medium_r,
.dop_organization
  .section_group
  .section
  .box
  .user_group
  ul
  .dop_drag_list
  .member
  .name_member {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.3125rem;
  letter-spacing: -0.028em;
}
.body_large_m,
.dop_organization .section_group .section .box .user_group .group_title .label,
.dop_organization .section_group .section .box .user_group .group_title .count {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: -0.032em;
}
.body_large_r {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: -0.032em;
}

.dop_organization {
  top: 160px;
  left: 81px;
  bottom: 16px;
  min-width: 360px !important;
  width: 360px;
  /* display: none; */
}
.dop_organization .no_selected {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--dop-color-text-basic-level3);
}
.dop_organization .wrap_popup {
  height: 100%;
}
.dop_organization .wrap_popup .wrap_popup_header {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 24px 24px 16px 24px;
  height: 68px;
  gap: 4px;
}
.dop_organization .wrap_popup .wrap_popup_header h3 {
  height: 1.75rem;
  font-size: 1.015rem;
  line-height: 1.75rem;
  letter-spacing: -0.08em;
  color: var(--dop-color-text-basic-level1);
  font-weight: 500;
}
.dop_organization .wrap_popup .wrap_popup_header p.count_txt {
  color: var(--dop-color-text-basic-level3);
}
.dop_organization .wrap_popup .wrap_popup_header .btn_icon:not(.goto_back) {
  position: absolute;
  right: 12px;
  top: 16px;
}
.dop_organization .wrap_popup .wrap_popup_header .btn_icon.goto_back {
  margin-left: -4px;
}
.dop_organization .wrap_popup .wrap_popup_header + .wrap_popup_contents {
  height: calc(100% - 68px);
}
.dop_organization .wrap_popup .wrap_popup_contents {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 0;
}
.dop_organization .wrap_popup .wrap_popup_contents .wrap_selected_list {
  display: flex;
  flex-direction: row;
  padding-top: 4px;
  min-height: 80px;
  overflow-x: auto;
  overflow-y: hidden;
}
.dop_organization .wrap_popup .wrap_popup_contents .wrap_selected_list::before {
  content: '';
  display: block;
  width: 10px;
  height: 100%;
  flex: 0 0 10px;
}
.dop_organization .wrap_popup .wrap_popup_contents .wrap_selected_list::after {
  content: '';
  display: block;
  width: 10px;
  height: 100%;
  flex: 0 0 10px;
}
.dop_organization
  .wrap_popup
  .wrap_popup_contents
  .wrap_selected_list
  .selected_member_list {
  display: block;
  flex: none;
}
.dop_organization
  .wrap_popup
  .wrap_popup_contents
  .wrap_selected_list
  .selected_member_list
  .selected_member {
  display: inline-block;
  margin: 0 4px;
}
.dop_organization
  .wrap_popup
  .wrap_popup_contents
  .wrap_selected_list
  .selected_member_list
  .selected_member
  .member {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 70px;
  gap: 4px;
}
.dop_organization
  .wrap_popup
  .wrap_popup_contents
  .wrap_selected_list
  .selected_member_list
  .selected_member
  .member
  .ic_xs.delete {
  position: absolute;
  top: 0;
  right: 9px;
  cursor: pointer;
}
.dop_organization
  .wrap_popup
  .wrap_popup_contents
  .wrap_selected_list
  .selected_member_list
  .selected_member
  .member
  .info {
  max-width: 100%;
}
.dop_organization
  .wrap_popup
  .wrap_popup_contents
  .wrap_selected_list
  .selected_member_list
  .selected_member
  .member
  .info
  .main {
  max-width: 100%;
}
.dop_organization
  .wrap_popup
  .wrap_popup_contents
  .wrap_selected_list
  .selected_member_list
  .selected_member
  .member
  .info
  .main
  .name_member {
  display: block;
  max-width: 100%;
  height: 19px;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1.125rem;
  letter-spacing: -0.02em;
  color: var(--dop-color-text-basic-level1);
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dop_organization
  .wrap_popup
  .wrap_popup_contents
  .wrap_selected_list
  .selected_member_list
  .selected_member
  .btn_medium.ghost.rect {
  font-size: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #c5c5c5;
  border-radius: 16px;
}
.dop_organization
  .wrap_popup
  .wrap_popup_contents
  .wrap_selected_list
  .selected_member_list
  .selected_member
  .btn_medium.ghost.rect
  .txt {
  font-size: 0;
  display: none;
}
.dop_organization .wrap_popup .wrap_popup_contents .popup_contents {
  height: calc(100% - 64px);
  display: flex;
  flex-direction: column;
}
.dop_organization .wrap_popup .wrap_popup_button {
  height: 72px;
  padding: 12px 24px 24px;
}
.dop_organization .wrap_popup .wrap_popup_button .btn_medium {
  width: 100%;
}
.dop_organization .wrap_popup .popup_btn_area.group_btn {
  justify-content: flex-end;
  padding: 24px;
}
.dop_organization .wrap_search {
  padding: 0 24px 16px;
}
.dop_organization .wrap_search .group_input .input_search {
  border: 1px solid var(--dop-color-field-border-basic);
  background-color: var(--dop-color-field-bg-basic);
}
.dop_organization .wrap_select {
  position: relative;
  padding: 0 24px;
  text-align: right;
  display: flex;
  flex-direction: row-reverse;
}
.dop_organization .wrap_select .btn_select {
  padding: 4px 8px;
  height: 28px;
}
.dop_organization .wrap_select > .select_list {
  top: 24px;
}
.dop_organization .wrap_total_check {
  position: relative;
  padding: 0 4px;
  text-align: right;
  display: flex;
  flex-direction: row-reverse;
}
.dop_organization
  .wrap_total_check
  .dop_checkbox
  input[type='checkbox']
  + label {
  flex-direction: row-reverse;
  align-items: center;
}
.dop_organization .container {
  position: relative;
  overflow: auto;
  width: 100%;
  height: 100%;
  padding: 0 24px;
  flex-grow: 1;
}
.dop_organization .container .group_organization {
  margin-left: -24px;
  padding-bottom: 16px;
}
.dop_organization .container .group_organization .list {
  position: relative;
  padding-left: 24px;
  margin-bottom: 4px;
}
.dop_organization .container .group_organization .list.no_depth {
  padding-left: 0;
}
.dop_organization .container .group_organization .list .subject {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 32px;
}
.dop_organization
  .container
  .group_organization
  .list
  .subject.foldable_group
  .info:hover {
  background-color: transparent;
}
.dop_organization
  .container
  .group_organization
  .list
  .subject.foldable_group
  .info
  .main
  .name_group {
  padding-right: 4px;
}
.dop_organization
  .container
  .group_organization
  .list
  .subject.foldable_group
  .info
  .main
  .name_group:hover {
  font-weight: bold;
  cursor: pointer;
}
.dop_organization
  .container
  .group_organization
  .list
  .subject.foldable_group
  .info
  .main
  a.count {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  cursor: pointer;
  height: 24px;
  padding: 0 4px 0 23px;
  border-radius: 13px;
  background: var(--dop-color-bg-basic-level2) no-repeat
    url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8.06667' cy='5.83337' r='3.46667' fill='%23CCCCCC'/%3E%3Cpath d='M3.20375 14.5H12.9296C13.4905 14.5 13.8615 13.9174 13.6243 13.4092C11.422 8.68993 4.71137 8.68993 2.50905 13.4092C2.27186 13.9174 2.64287 14.5 3.20375 14.5Z' fill='%23CCCCCC'/%3E%3C/svg%3E%0A")
    4px 4px;
}
.dop_organization
  .container
  .group_organization
  .list
  .subject.foldable_group
  .info
  .main
  a.count::after {
  content: '';
  display: block;
  width: 9px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='17' viewBox='0 0 8 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 5.5L6 8.5L3 11.5' stroke='%23999999' stroke-opacity='0.4' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.dop_organization
  .container
  .group_organization
  .list
  .subject.foldable_group
  .info
  .main
  a.count:hover {
  background-color: var(--dop-color-bg-basic-level2-hover);
}
.dop_organization
  .container
  .group_organization
  .list
  .subject:not(.foldable_group)
  .info:hover {
  background-color: var(--dop-color-bg-base-hover);
}
.dop_organization
  .container
  .group_organization
  .list
  .subject:not(.foldable_group).active {
  background-color: var(--dop-color-bg-base-hover);
}
.dop_organization .container .group_organization .list .subject .info {
  position: relative;
  padding: 4px;
  display: flex;
  flex-direction: column;
  gap: 0;
  border-radius: 8px;
}
.dop_organization .container .group_organization .list .subject .info:hover {
  cursor: pointer;
}
.dop_organization .container .group_organization .list .subject .main {
  display: flex;
  align-items: center;
  gap: 2px;
}
.dop_organization .container .group_organization .list .subject .name_group {
  white-space: nowrap;
  font-size: 0.875rem;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: var(--dop-color-text-basic-level1);
  font-weight: 500;
}
.dop_organization .container .group_organization .list .subject .count {
  font-style: normal;
  white-space: nowrap;
  font-size: 0.875rem;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: var(--dop-color-text-basic-level3);
  font-weight: 500;
}
.dop_organization .container .group_organization .list .subject.no_sub::before {
  content: '';
  width: 28px;
  height: 28px;
  display: block;
}
.dop_organization .container .group_organization .list .member {
  position: relative;
  display: inline-flex;
  align-items: center;
  min-height: 44px;
  padding: 4px;
  border-radius: 8px;
}
.dop_organization .container .group_organization .list .member .info {
  display: flex;
  flex-direction: column;
}
.dop_organization .container .group_organization .list .member .info .main {
  display: flex;
  align-items: center;
  padding-left: 8px;
}
.dop_organization
  .container
  .group_organization
  .list
  .member
  .info
  .divider.dot {
  display: inline-flex;
  margin-left: 5px;
  padding-right: 5px;
}
.dop_organization
  .container
  .group_organization
  .list
  .member
  .info
  .divider.dot::before {
  width: 2px;
  height: 2px;
  background: #7f8082;
}
.dop_organization .container .group_organization .list .member .master {
  position: absolute;
  top: 24px;
  right: auto;
  bottom: auto;
  left: 26px;
}
.dop_organization .container .group_organization .list .member:hover {
  background-color: rgba(221, 221, 221, 0.3137254902);
  cursor: pointer;
}
.dop_organization .container .group_organization .list .member.active {
  background-color: rgba(221, 221, 221, 0.3137254902);
  cursor: pointer;
}
.dop_organization .container .group_organization .list .member .name_member {
  white-space: nowrap;
  padding: 0 4px;
  font-size: 0.875rem;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: #2b2c2d;
  font-weight: 400;
}
.dop_organization .container .group_organization .list .additional {
  margin-top: 2px;
  white-space: nowrap;
  padding-left: 8px;
  font-size: 0.75rem;
  line-height: 1.125rem;
  letter-spacing: -0.02em;
  color: #7f8082;
  font-weight: 400;
}
.dop_organization .container .group_organization .list .body_medium {
  white-space: nowrap;
}
.dop_organization .container .title {
  color: #7f8082 !important;
  padding: 8px 0 4px 4px;
}
.dop_organization .container .title_group {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 4px;
  margin: 0 -4px;
}
.dop_organization .container .title_group .title {
  flex-grow: 1;
}
.dop_organization .container .title_group a {
  position: absolute;
  right: -8px;
}
.dop_organization .container .wrap_tabs {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: -24px;
  margin-right: -24px;
}
.dop_organization .container .wrap_tabs .dop_tabs {
  gap: 0;
}
.dop_organization .container .wrap_tabs .dop_tabs .tab_item {
  display: flex;
  justify-content: center;
  width: 50%;
  border-radius: 0;
}
.dop_organization .container .wrap_tabs .dop_tabs .tab_item .count {
  color: var(--dop-color-text-basic-level3);
}
.dop_organization .container .tab_content {
  height: calc(100% - 57px);
  margin-right: -24px;
  padding-top: 16px;
  padding-right: 24px;
  overflow: auto;
  justify-content: flex-start;
}
.dop_organization.open {
  display: block;
}
.dop_organization.dop_selectable_org .wrap_tabs {
  margin-bottom: 0;
}
.dop_organization.dop_selectable_org .wrap_popup_contents {
  padding: 0;
}
.dop_organization.dop_selectable_org
  .wrap_popup_contents
  .attached_file
  .file_name {
  line-height: 24px;
}
.dop_organization.dop_selectable_org
  .container
  .group_organization
  .list
  .subject {
  border-radius: 8px;
  padding-right: 4px;
}
.dop_organization.dop_selectable_org
  .container
  .group_organization
  .list
  .subject
  > .option_list {
  padding: 4px 0;
  width: calc(100% - 24px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.dop_organization.dop_selectable_org
  .container
  .group_organization
  .list
  .subject
  > .option_list
  .name_group {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dop_organization.dop_selectable_org
  .container
  .group_organization
  .list
  .subject
  > .option_list
  .info:hover {
  background-color: transparent;
}
.dop_organization.dop_selectable_org
  .container
  .group_organization
  .list
  .subject
  > .option_list
  .info {
  width: calc(100% - 24px);
  padding: 0;
}
.dop_organization.dop_selectable_org
  .container
  .group_organization
  .list
  .subject
  > .option_list
  .info
  .main:hover {
  background-color: var(--dop-color-bg-base-hover);
  border-radius: 8px;
}
.dop_organization.dop_selectable_org
  .container
  .group_organization
  .list
  .subject
  > .option_list
  .dop_checkbox,
.dop_organization.dop_selectable_org
  .container
  .group_organization
  .list
  .subject
  > .option_list
  .dop_radio {
  flex: 0 0 16px;
}
.dop_organization.dop_selectable_org
  .container
  .group_organization
  .list
  .subject
  .main {
  display: inline-flex;
  align-items: center;
  width: fit-content;
  gap: 2px;
  flex-direction: row;
  max-width: 100%;
  padding: 4px;
}
.dop_organization.dop_selectable_org
  .container
  .group_organization
  .list
  .member {
  width: 100%;
}
.dop_organization.dop_selectable_org
  .container
  .group_organization
  .list
  .member
  .option_list {
  width: calc(100% - 32px);
  padding-left: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.dop_organization.dop_selectable_org
  .container
  .group_organization
  .list
  .member
  .option_list
  .main {
  max-width: 100%;
}
.dop_organization.dop_selectable_org
  .container
  .group_organization
  .list
  .member
  .option_list
  .main
  .name_member {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 4px;
}
.dop_organization.dop_selectable_org
  .container
  .group_organization
  .list
  .member
  .option_list
  .info {
  width: calc(100% - 16px);
}
.dop_organization.dop_selectable_org
  .container
  .group_organization
  .list
  .member
  .option_list
  .dop_radio
  input[type='radio']
  + label {
  min-height: 16px;
}
.dop_organization.dop_selectable_org
  .container
  .group_organization
  .list
  .member
  .option_list
  .dop_checkbox {
  flex: 0 0 16px;
}
.dop_organization.dop_selectable_org .wrap_selected_list {
  margin-bottom: 8px;
}
.dop_organization.dop_selectable_org .wrap_search {
  padding: 0 24px 8px;
}
.dop_organization.dop_selectable_org .attached_file {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding-top: 24px;
}
.dop_organization.dop_selectable_org .attached_file .file_type {
  height: 24px;
}
.dop_organization.dop_selectable_org .popup_btn_area.group_btn {
  padding-top: 16px;
}
.dop_organization.dop_forwarding_org .wrap_tabs {
  margin-bottom: 0;
  padding: 0 24px;
  border-bottom: 1px solid var(--dop-color-border-basic-level1);
}
.dop_organization.dop_forwarding_org .wrap_popup_contents .tab_content {
  height: calc(100% - 160px) !important;
}
.dop_organization.dop_forwarding_org
  .wrap_popup_contents
  .tab_content
  .wrap_search {
  padding: 0 24px;
}
.dop_organization.dop_forwarding_org
  .wrap_popup_contents
  .tab_content
  .wrap_selected_list {
  min-height: 104px;
}
.dop_organization.dop_forwarding_org
  .wrap_popup_contents
  .tab_content
  .selected_member_list {
  padding-top: 24px;
}
.dop_organization.dop_forwarding_org .wrap_popup_contents .container {
  padding: 8px 16px 0;
}
.dop_organization.dop_forwarding_org
  .wrap_popup_contents
  .container
  .group_organization
  .list
  .subject {
  border-radius: 8px;
  padding-right: 4px;
}
.dop_organization.dop_forwarding_org
  .wrap_popup_contents
  .container
  .group_organization
  .list
  .subject
  > .option_list {
  padding: 4px 0;
  width: calc(100% - 24px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.dop_organization.dop_forwarding_org
  .wrap_popup_contents
  .container
  .group_organization
  .list
  .subject
  > .option_list
  .name_group {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dop_organization.dop_forwarding_org
  .wrap_popup_contents
  .container
  .group_organization
  .list
  .subject
  > .option_list
  .info {
  width: calc(100% - 24px);
}
.dop_organization.dop_forwarding_org
  .wrap_popup_contents
  .container
  .group_organization
  .list
  .subject
  > .option_list
  .dop_checkbox,
.dop_organization.dop_forwarding_org
  .wrap_popup_contents
  .container
  .group_organization
  .list
  .subject
  > .option_list
  .dop_radio {
  flex: 0 0 16px;
}
.dop_organization.dop_forwarding_org
  .wrap_popup_contents
  .container
  .group_organization
  .list
  .member {
  width: 100%;
}
.dop_organization.dop_forwarding_org
  .wrap_popup_contents
  .container
  .group_organization
  .list
  .member
  .option_list {
  width: calc(100% - 32px);
  padding-left: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.dop_organization.dop_forwarding_org
  .wrap_popup_contents
  .container
  .group_organization
  .list
  .member
  .option_list
  .main {
  max-width: 100%;
}
.dop_organization.dop_forwarding_org
  .wrap_popup_contents
  .container
  .group_organization
  .list
  .member
  .option_list
  .main
  .name_member {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dop_organization.dop_forwarding_org
  .wrap_popup_contents
  .container
  .group_organization
  .list
  .member
  .option_list
  .info {
  width: calc(100% - 16px);
}
.dop_organization.dop_forwarding_org
  .wrap_popup_contents
  .container
  .group_organization
  .list
  .member
  .option_list
  .dop_radio
  input[type='radio']
  + label {
  min-height: 16px;
}
.dop_organization.dop_forwarding_org .wrap_popup_contents .wrap_selected_list {
  margin-bottom: 8px;
}
.dop_organization.dop_forwarding_org .wrap_popup_contents .wrap_search {
  padding: 0 0 8px;
}
.dop_organization.dop_forwarding_org .attached_file {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 24px 24px 0;
}
.dop_organization.dop_forwarding_org .attached_file .file_type {
  height: 24px;
}
.dop_organization.dop_forwarding_org .attached_file .file_name,
.dop_organization.dop_forwarding_org .attached_file .file_name span {
  line-height: 24px;
}
.dop_organization.dop_forwarding_org .popup_btn_area.group_btn {
  padding: 16px 24px 0;
}
.dop_organization .wrap_tabs {
  margin-top: -8px;
  margin-bottom: 24px;
  padding: 0 24px;
  border-bottom: 1px solid var(--dop-color-border-basic-level1);
}
.dop_organization .wrap_tabs .dop_tabs .tab_item.square.active::after {
  bottom: 0;
}
.dop_organization .tab_content {
  position: relative;
  height: calc(100% - 120px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
}
.dop_organization .section_group {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: stretch;
  overflow: hidden;
  flex-grow: 1;
}
.dop_organization .section_group + .dop_table {
  margin-top: 20px;
}
.dop_organization .section_group .section {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.dop_organization .section_group .section.org {
  width: 42.5%;
}
.dop_organization .section_group .section.selected {
  width: 57.5%;
}
.dop_organization .section_group .section .group {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 8px;
  width: 100%;
}
.dop_organization .section_group .section .btn_area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 26px;
}
.dop_organization .section_group .section .btn_area .btn_icon {
  width: 26px;
  height: 26px;
  border-radius: 16px;
  border: 1px solid var(--dop-color-border-basic-level1);
  flex: 0 0 26px;
}
.dop_organization .section_group .section .box {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid var(--dop-color-border-level4);
  border-radius: 16px;
  overflow: hidden;
}
.dop_organization .section_group .section .box .wrap_search {
  padding: 24px 24px 8px;
}
.dop_organization .section_group .section .box .container {
  padding: 8px 24px 0;
  height: calc(100% - 72px);
  overflow: auto;
}
.dop_organization .section_group .section .box .user_group {
  position: relative;
  height: 100%;
}
.dop_organization .section_group .section .box .user_group .group_title {
  display: flex;
  gap: 4px;
  padding: 16px 24px 12px;
}
.dop_organization .section_group .section .box .user_group .group_title .label {
  color: var(--dop-color-text-basic-level1);
}
.dop_organization .section_group .section .box .user_group .group_title .count {
  color: var(--dop-color-text-basic-level3);
}
.dop_organization .section_group .section .box .user_group .wrap_scroll {
  position: relative;
  height: calc(100% - 51px);
  overflow: auto;
}
.dop_organization .section_group .section .box .user_group ul .dop_drag_list {
  padding: 0 12px;
}
.dop_organization
  .section_group
  .section
  .box
  .user_group
  ul
  .dop_drag_list
  .member {
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
}
.dop_organization
  .section_group
  .section
  .box
  .user_group
  ul
  .dop_drag_list
  .member.has_team_option {
  width: 100%;
}
.dop_organization
  .section_group
  .section
  .box
  .user_group
  ul
  .dop_drag_list
  .member.has_team_option
  .info {
  flex-direction: row;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.dop_organization
  .section_group
  .section
  .box
  .user_group
  ul
  .dop_drag_list
  .member.has_team_option
  .info
  .main {
  padding-right: 16px;
}
.dop_organization
  .section_group
  .section
  .box
  .user_group
  ul
  .dop_drag_list
  .member.has_team_option
  .info
  .option {
  display: flex;
  padding-right: 16px;
}
.dop_organization
  .section_group
  .section
  .box
  .user_group
  ul
  .dop_drag_list
  .member.has_team_option
  .dop_checkbox
  input
  + label {
  align-items: center !important;
}
.dop_organization
  .section_group
  .section
  .box
  .user_group
  ul
  .dop_drag_list
  .member
  .icon.master {
  position: absolute;
  top: 50%;
  transform: translateY(2px);
  right: auto;
  bottom: auto;
  left: 70px;
}
.dop_organization
  .section_group
  .section
  .box
  .user_group
  ul
  .dop_drag_list
  .member
  .group_input
  + .avatar_medium
  + .icon.master {
  left: 140px;
}
.dop_organization
  .section_group
  .section
  .box
  .user_group
  ul
  .dop_drag_list
  .member
  .info {
  padding-left: 4px;
}
.dop_organization
  .section_group
  .section
  .box
  .user_group
  ul
  .dop_drag_list
  .member
  .name_member {
  color: var(--dop-color-text-basic-level1);
}
.dop_organization
  .section_group
  .section
  .box
  .user_group
  ul
  .dop_drag_list
  .member
  .additional {
  color: var(--dop-color-text-basic-level3);
}
.dop_organization .section_group .section.row_3 .group {
  height: 33.3333333333%;
}

/*# sourceMappingURL=organization.css.map */
